/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import { Box, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import EmptyAddItem from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/empty/components/empty-add-item/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isMobileAndInMvpOrExistingUsersExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { YourWorkItemListSkeleton } from '@atlassian/jira-skeletons/src/ui/your-work/YourWorkItemListSkeleton.tsx';
import {
	MAX_ITEMS_PER_PAGE,
	ASSIGNED_TAB_KEY,
	SUCCESS_STATE,
	ERROR_STATE,
	EMPTY_STATE,
} from '../../../common/constants/tab-content.tsx';
import type { TabProps } from '../../../common/types/tabs.tsx';
import EmptyState from '../../../common/ui/empty-state/index.tsx';
import ErrorState from '../../../common/ui/error-state/index.tsx';
import ItemCounter from '../../../common/ui/item-counter/index.tsx';
import ItemList from '../../../common/ui/item-list/index.tsx';
import ReloadingSpinner from '../../../common/ui/reloading-spinner/index.tsx';
import { TabFooter } from '../../../common/ui/tab-footer/styled.tsx';
import { LinkWithAnalytics } from '../../../common/ui/with-analytics/index.tsx';
import {
	EndInteractionsTracker,
	trimItemsBySection,
	useFireErrorOrSuccessAnalytics,
} from '../../../common/utils.tsx';
import AssignedToUserImageUrl from './assets/jira-laptop-done.svg';
import messages from './messages.tsx';

const styles = cssMap({
	itemCounter: {
		marginBottom: token('space.050'),
	},
});

const FOOTER_HREF =
	'/issues/?jql=assignee%20%3D%20currentUser()%20AND%20statusCategory%20in%20(2%2C%204)%20order%20by%20statusCategory%20DESC%2C%20updated%20DESC';

const AssignedToMeTab = (props: TabProps) => {
	const {
		itemProviderResult: { data, error, loading, reloading },
	} = props;

	const { formatMessage } = useIntl();

	useFireErrorOrSuccessAnalytics(!!data.total, error, loading, 'assignedToMe');

	if (error && !data.total) {
		return (
			<>
				<ErrorState />
				<EndInteractionsTracker transitionKey={ASSIGNED_TAB_KEY} renderState={ERROR_STATE} />
			</>
		);
	}

	if (loading) {
		return <YourWorkItemListSkeleton />;
	}

	if (
		(!data || !data.sections || data.sections.length === 0) &&
		isMobileAndInMvpOrExistingUsersExperiment()
	) {
		return (
			<>
				<EmptyState
					header={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.emptyStateHeaderIssueTermRefresh
							: messages.emptyStateHeader,
					)}
					description={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.emptyStateDescriptionIssueTermRefresh
							: messages.emptyStateDescription,
					)}
					renderImage={() => (
						// renderImage doesn't add an image wrapper whereas imageUrl does
						<Box paddingBlockEnd="space.100">
							<Inline alignInline="center">
								<EmptyAddItem alt="" width={154} />
							</Inline>
						</Box>
					)}
				/>
				<EndInteractionsTracker transitionKey={ASSIGNED_TAB_KEY} renderState={EMPTY_STATE} />
			</>
		);
	}

	if (!data || !data.sections || data.sections.length === 0) {
		return (
			<>
				<EmptyState
					header={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.emptyStateHeaderIssueTermRefresh
							: messages.emptyStateHeader,
					)}
					description={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.emptyStateDescriptionIssueTermRefresh
							: messages.emptyStateDescription,
					)}
					imageUrl={AssignedToUserImageUrl}
				/>
				<EndInteractionsTracker transitionKey={ASSIGNED_TAB_KEY} renderState={EMPTY_STATE} />
			</>
		);
	}

	const footer =
		data.total && data.total > MAX_ITEMS_PER_PAGE ? (
			<TabFooter>
				<div css={styles.itemCounter}>
					<ItemCounter displayedCount={MAX_ITEMS_PER_PAGE} totalCount={data.total} />
				</div>
				<LinkWithAnalytics
					href={FOOTER_HREF}
					actionSubjectId="viewAllAssignedToMeLink"
					appearance="link"
					buttonLink
				>
					{formatMessage(messages.seeAllIssuesAssignedToMe)}
				</LinkWithAnalytics>
			</TabFooter>
		) : null;

	return (
		<>
			{reloading && <ReloadingSpinner />}
			<ItemList sections={trimItemsBySection(props.maxItems, data.sections)} />
			{footer}
			<EndInteractionsTracker transitionKey={ASSIGNED_TAB_KEY} renderState={SUCCESS_STATE} />
		</>
	);
};

export default AssignedToMeTab;
