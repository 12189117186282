import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	sectionTitle: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.section-title',
		defaultMessage: 'Recent projects',
		description: 'Header title used for deep dive cards section',
	},
	queuesHeading: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.queues-heading',
		defaultMessage: 'Queues in the project',
		description: 'Heading for dropdown that displays list of queues',
	},
	boardsHeading: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.boards-heading',
		defaultMessage: 'Boards in the project',
		description: 'Heading for dropdown that displays list of boards',
	},
	assignedToMe: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.assigned-to-me',
		defaultMessage: 'My open issues',
		description: 'Link to assigned to me filer in project issue navigator',
	},
	assignedToMeIdeas: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.assigned-to-me-ideas',
		defaultMessage: 'My ideas',
		description: 'Link to assigned to me filer in project issue navigator',
	},
	doneIssues: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.done-issues',
		defaultMessage: 'Done issues',
		description: 'Link to Done issues filter in project issue navigator',
	},
	coreBoardTitle: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.core-board-title',
		defaultMessage: 'Board',
		description: 'A Jira business project board name',
	},
	assignedToMeIssueTermRefresh: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.assigned-to-me-issue-term-refresh',
		defaultMessage: 'My open work items',
		description: 'Link to assigned to me filer in project issue navigator',
	},
	doneIssuesIssueTermRefresh: {
		id: 'deep-dive-cards-section.services.deep-dive-provider.done-issues-issue-term-refresh',
		defaultMessage: 'Done work items',
		description: 'Link to Done issues filter in project issue navigator',
	},
});
