import React, { useMemo, useRef, Fragment } from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';
import Page from '@atlaskit/page';
import { token } from '@atlaskit/tokens';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isMobileAndInMvpOrExistingUsersExperiment } from '@atlassian/jira-mobile-web/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { CurrentUserProvider as CurrentUserProviderType } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { RelayMigrationProvider } from '@atlassian/jira-relay-apollo-migration-utils/src/services/use-query/index.tsx';
import type { RouteResourceResponse } from '@atlassian/react-resource-router';
import { STARRED_TAB_KEY, VIEWED_TAB_KEY } from '../common/constants/tab-content.tsx';
import type { PrefetchResult } from '../common/types/item-provider.tsx';
import type { TabConfig } from '../common/types/tabs.tsx';
import LastVisitedTabProvider, {
	LastVisitedTabProviderFF,
} from '../services/last-visited-tab-provider/index.tsx';
import { NAV_V3_TABS, NAV_V4_TABS, MOBILE_WEB_TABS } from './constants.tsx';
import { GlobalPagesDocumentTitle } from './document-title/index.tsx';
import FullSkeleton from './full-skeleton/index.tsx';
import Header from './header/index.tsx';
import messages from './messages.tsx';
import RecentProjects from './recent-projects/index.tsx';
import { RightPanelLayout } from './right-panel-layout/index.tsx';
import TabContainer from './tab-container/index.tsx';
import { TabContainerFF } from './tab-container/tab-container-ff.tsx';
import { services, servicesNav4 } from './utils.tsx';

export type Props = {
	prefetchResult: Partial<RouteResourceResponse<PrefetchResult>>;
	// externalProviders are providers that are outside the scope of Jira Home
	externalProviders?: {
		CurrentUser?: typeof CurrentUserProviderType;
	};
};

const onTabSelected = memoizeOne(
	(setLastVisitedTab) => (selectedTab: string) => setLastVisitedTab(selectedTab),
);

export const JiraHome = ({ prefetchResult }: Props) => {
	const isMobileWebAndInMVPExperiment = isMobileAndInMvpOrExistingUsersExperiment();
	const intl = useIntl();
	const tabConfigForNav = getWillShowNav4() ? NAV_V4_TABS : NAV_V3_TABS;

	const tabConfigsToUse = isMobileWebAndInMVPExperiment ? MOBILE_WEB_TABS : tabConfigForNav;

	const intlTabConfigs: TabConfig[] = useMemo(
		() =>
			tabConfigsToUse.map((tab) => {
				const config: TabConfig = {
					...tab,
					id: tab.id,
					// generate an intld label for the Atlaskit Tab Navigation component
					// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ dashboards: MessageDescriptorV2; projects: MessageDescriptorV2; experimentPreferenceChangeSuccessMessage: MessageDescriptorV2; ... 6 more ...; title: MessageDescriptorV2; }'.
					label: intl.formatMessage(messages[tab.id]),
				};

				return config;
			}),
		[intl, tabConfigsToUse],
	);

	const slowRunOnce = useRef(false);

	// eslint-disable-next-line jira/ff/no-preconditioning
	if (fg('uip_slow_test') && !slowRunOnce.current) {
		slowRunOnce.current = true;
		let timeNow = 0;
		let timeDelayForExperiment = 0;

		try {
			timeNow = performance.now();
			timeDelayForExperiment = expVal('uip_slow_test_experiment', 'delay', 0);
		} catch (e) {
			// Silently fail, we have safe defaults above if we fail to get them
		}

		if (timeDelayForExperiment > 0 && timeDelayForExperiment < 1000) {
			/* eslint-disable-next-line no-empty */
			while (performance.now() - timeNow <= timeDelayForExperiment) {}
		}
	}

	const RightPanelWrapper = isMobileWebAndInMVPExperiment ? Fragment : RightPanelLayout;

	return (
		<RelayMigrationProvider>
			<ContextualAnalyticsData sourceType={SCREEN} sourceName="jiraHome">
				<ReportErrors id="root" packageName="jiraHome">
					<RightPanelWrapper>
						<div data-test-id="global-pages.home.ui.home">
							<GlobalPagesDocumentTitle data-testid="global-pages.home.ui.global-pages-document-title" />
							<PageWrapper>
								<Page>
									<FireScreenAnalytics />
									<Header />
									{getWillShowNav4() ? (
										// @ts-expect-error Type 'Partial<RouteResourceResponse<PrefetchResult>>' is not assignable to type 'RouteResourceResponse<PrefetchResult> | undefined'.
										<LastVisitedTabProviderFF prefetchResult={prefetchResult}>
											{({ queriedTab, lastVisitedTab, setLastVisitedTab }) => {
												let matchingConfigs = intlTabConfigs.filter(
													(config) => config.id === lastVisitedTab,
												);
												switch (queriedTab) {
													case 'starred':
														matchingConfigs = intlTabConfigs.filter(
															(config) => config.id === STARRED_TAB_KEY,
														);
														break;
													case 'viewed':
														matchingConfigs = intlTabConfigs.filter(
															(config) => config.id === VIEWED_TAB_KEY,
														);
														break;
													default:
														break;
												}

												if (
													matchingConfigs.length === 0 &&
													prefetchResult.loading &&
													!isMobileWebAndInMVPExperiment
												) {
													return (
														<FullSkeleton
															tabConfigs={intlTabConfigs}
															deepDiveCards={<RecentProjects prefetchResult={undefined} />}
														/>
													);
												}

												const selectedTab =
													matchingConfigs.length > 0 ? matchingConfigs[0] : intlTabConfigs[0];

												return (
													<>
														{!isMobileWebAndInMVPExperiment && (
															<RecentProjects
																// @ts-expect-error Type 'Partial<RouteResourceResponse<PrefetchResult>>' is not assignable to type 'RouteResourceResponse<PrefetchResult> | undefined'.
																prefetchResult={prefetchResult}
															/>
														)}
														<TabContainerFF
															services={servicesNav4}
															// @ts-expect-error - TS2322 - Type 'Partial<RouteResourceResponse<PrefetchResult>>' is not assignable to type 'RouteResourceResponse<PrefetchResult>'.
															prefetchResult={prefetchResult}
															tabConfigs={intlTabConfigs}
															defaultSelected={selectedTab}
															onTabChange={onTabSelected(setLastVisitedTab)}
														/>
													</>
												);
											}}
										</LastVisitedTabProviderFF>
									) : (
										// @ts-expect-error Type 'Partial<RouteResourceResponse<PrefetchResult>>' is not assignable to type 'RouteResourceResponse<PrefetchResult> | undefined'.
										<LastVisitedTabProvider prefetchResult={prefetchResult}>
											{({ lastVisitedTab, setLastVisitedTab }) => {
												const matchingConfigs = intlTabConfigs.filter(
													(config) => config.id === lastVisitedTab,
												);

												if (
													matchingConfigs.length === 0 &&
													prefetchResult.loading &&
													!isMobileWebAndInMVPExperiment
												) {
													return (
														<FullSkeleton
															tabConfigs={intlTabConfigs}
															deepDiveCards={<RecentProjects prefetchResult={undefined} />}
														/>
													);
												}

												const selectedTab =
													matchingConfigs.length > 0 ? matchingConfigs[0] : intlTabConfigs[0];

												return (
													<>
														{!isMobileWebAndInMVPExperiment && (
															<RecentProjects
																// @ts-expect-error Type 'Partial<RouteResourceResponse<PrefetchResult>>' is not assignable to type 'RouteResourceResponse<PrefetchResult> | undefined'.
																prefetchResult={prefetchResult}
															/>
														)}
														<TabContainer
															services={services}
															// @ts-expect-error - TS2322 - Type 'Partial<RouteResourceResponse<PrefetchResult>>' is not assignable to type 'RouteResourceResponse<PrefetchResult>'.
															prefetchResult={prefetchResult}
															tabConfigs={intlTabConfigs}
															defaultSelected={selectedTab}
															onTabChange={onTabSelected(setLastVisitedTab)}
														/>
													</>
												);
											}}
										</LastVisitedTabProvider>
									)}
								</Page>
							</PageWrapper>
						</div>
					</RightPanelWrapper>
				</ReportErrors>
			</ContextualAnalyticsData>
		</RelayMigrationProvider>
	);
};

export default JiraHome;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PageWrapper = styled.div({
	minWidth: '329px',
	margin: `
        ${token('space.075', '6px')} ${token('space.100', '8px')} ${token('space.400', '32px')}
    `,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TabFooter = styled.div({
	textAlign: 'center',
	marginTop: token('space.400', '32px'),
});

TabFooter.displayName = 'TabFooter';
