import type { boardsTable_home$data } from '@atlassian/jira-relay/src/__generated__/boardsTable_home.graphql.ts';

export const JIRA_SEARCH_RESULT_PROJECT_CONTAINER =
	'SearchResultJiraBoardProjectContainer' as const;
export const JIRA_SEARCH_RESULT_USER_CONTAINER = 'SearchResultJiraBoardUserContainer' as const;
export const JIRA_SEARCH_RESULT_BOARD = 'SearchResultJiraBoard' as const;

type JiraBoardSearchResultType = typeof JIRA_SEARCH_RESULT_BOARD;

export type BoardRow = {
	__typename: JiraBoardSearchResultType;
	id: string;
	title: string;
	url: string;
	favourite?: boolean;
	iconUrl?: string;
	container: AnyBoardContainer;
};

export type AnyBoard = NonNullable<boardsTable_home$data['edges'][number]['node']>;
type AnyBoardContainer = NonNullable<AnyBoard['container']>;

export type BoardContainerProject = Extract<
	AnyBoardContainer,
	{ __typename: typeof JIRA_SEARCH_RESULT_PROJECT_CONTAINER }
>;

export type BoardContainerUser = Extract<
	AnyBoardContainer,
	{ __typename: typeof JIRA_SEARCH_RESULT_USER_CONTAINER }
>;

export type ProjectBoard = Omit<AnyBoard, 'container'> & { container: BoardContainerProject };
export type UserBoard = Omit<AnyBoard, 'container'> & { container: BoardContainerUser };
