import { useEffect, useState } from 'react';
import {
	CORE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	type ProjectType,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { DeepDiveInfoItem, Project } from '../../../../common/types.tsx';
import { messages } from '../../messages.tsx';
import type { MyAssignedIssuesInProjectsResponse } from './types.tsx';

const getAssignedToMeJQL = (projectKey: string) =>
	`assignee = currentUser() AND project='${projectKey}' AND statusCategory != 3`;

const getReportedByMeJQL = (projectKey: string) =>
	`reporter = currentUser() AND project='${projectKey}' AND statusCategory != 3`;

const getMyIssuesProjectFragment = (projectKey: string, type: ProjectType) =>
	type === PRODUCT_DISCOVERY_PROJECT
		? `${projectKey}_reported: issues(jql:"${getReportedByMeJQL(projectKey)}",first:1) {
           totalCount
         }`
		: `${projectKey}_assigned: issues(jql:"${getAssignedToMeJQL(projectKey)}",first:1) {
           totalCount
         }`;

const getProjectFragment = (projectKey: string, type: ProjectType) =>
	`${getMyIssuesProjectFragment(projectKey, type)}`;

export const operationName = 'getDoneAndAssignedToMeIssuesByProjectsForDeepDiveCards';

export const getGraphQLQuery = (projects: Project[]) => `query ${operationName} {
            ${projects.map(({ key, projectType }) => getProjectFragment(key, projectType)).join()}
        }`;

const getReportedFilterLink = (key: string) =>
	`?jql=${encodeURIComponent(
		`reporter = currentUser() AND project='${key}' AND statusCategory != 3`,
	)}`;

const getProjectLinkUrl = (baseUrl: string, project: Project, queryParam: string) => {
	const LEGACY_URL = `/projects/${project.key}/issues/${queryParam}`;

	if (project.isSimplified && project.projectType === SOFTWARE_PROJECT) {
		return `/jira/software/projects/${project.key}/issues/${queryParam}`;
	}

	switch (project.projectType) {
		case SERVICE_DESK_PROJECT:
			return `/jira/servicedesk/projects/${project.key}/issues/${queryParam}`;
		case CORE_PROJECT:
			return `/jira/core/projects/${project.key}/issues/${queryParam}`;
		case SOFTWARE_PROJECT:
			return `/jira/software/c/projects/${project.key}/issues/${queryParam}`;
		// Currently there is no way to like to a JPD view with filters preselected
		// and if you go to a normal software page the sidebar is project because
		// JPD doesn't use the standard sidebar, so we'll go to search.
		case PRODUCT_DISCOVERY_PROJECT:
			return `/issues/${queryParam}`;
		default:
			return LEGACY_URL;
	}
};

type FilterResults = {
	loading?: boolean;
	error?: boolean;
	data?: {
		[project: string]: DeepDiveInfoItem[];
	};
};

type ProjectLink = {
	count: number;
	error: boolean;
	loading: boolean;
	message: string;
};

const fetchMyAssignedIssuesInProjects = (
	baseUrl: string,
	projects: Project[],
): Promise<MyAssignedIssuesInProjectsResponse | null> => {
	if (projects.length > 0) {
		const graphqlQuery = getGraphQLQuery(projects);
		const queryUrl = '/rest/gira/latest/?deep-dive-assignedIssueCount=1';
		return fetchJson(queryUrl, {
			method: 'POST',
			body: JSON.stringify({
				operationName,
				query: graphqlQuery,
			}),
		});
	}
	return Promise.resolve(null);
};

export const buildProjectDeepDiveInfo = (
	baseUrl: string,
	project: Project,
	assigned?: ProjectLink,
	done?: ProjectLink,
	reported?: ProjectLink,
) => ({
	[project.key]: [
		...(assigned
			? [
					{
						content: assigned.message,
						href: getProjectLinkUrl(baseUrl, project, '?filter=myopenissues'),
						itemCount: assigned.count,
						isLoading: assigned.loading,
						error: assigned.error,
					},
				]
			: []),
		...(done
			? [
					{
						content: done.message,
						href: getProjectLinkUrl(baseUrl, project, '?filter=doneissues'),
						itemCount: done.count,
						isLoading: done.loading,
						error: done.error,
					},
				]
			: []),
		...(reported
			? [
					{
						content: reported.message,
						href: getProjectLinkUrl(baseUrl, project, getReportedFilterLink(project.key)),
						itemCount: reported.count,
						isLoading: reported.loading,
						error: reported.error,
					},
				]
			: []),
	],
});

const useFetchAssignedIssuesCountPerProject = (
	recentProjects: Project[],
	supportedProjects: ProjectType[] = [SOFTWARE_PROJECT, CORE_PROJECT],
) => {
	const baseUrl = '';
	const { formatMessage } = useIntl();
	const [results, setResults] = useState<FilterResults>({ loading: false, error: false });

	useEffect(() => {
		let didCancel = false;
		(async () => {
			const softwareProjects = recentProjects.filter((project) =>
				supportedProjects.includes(project.projectType),
			);

			const buildDeepDiveInfoItems = (fetchResult?: MyAssignedIssuesInProjectsResponse) =>
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				softwareProjects.reduce<Record<string, any>>((acc, project: Project) => {
					if (project.projectType === PRODUCT_DISCOVERY_PROJECT) {
						const reported = fetchResult && fetchResult.data[`${project.key}_reported`];
						return {
							// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							...acc,
							...buildProjectDeepDiveInfo(baseUrl, project, undefined, undefined, {
								count: reported ? reported.totalCount : -1,
								error: !reported,
								loading: false,
								message: formatMessage(messages.assignedToMeIdeas),
							}),
						};
					}

					const assigned = fetchResult && fetchResult.data[`${project.key}_assigned`];
					return {
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...acc,
						...buildProjectDeepDiveInfo(
							baseUrl,
							project,
							{
								count: assigned ? assigned.totalCount : -1,
								error: !assigned,
								loading: false,
								message: formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.assignedToMeIssueTermRefresh
										: messages.assignedToMe,
								),
							},
							{
								count: -1,
								error: false,
								loading: false,
								message: formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.doneIssuesIssueTermRefresh
										: messages.doneIssues,
								),
							},
						),
					};
				}, {});

			try {
				setResults({
					loading: false,
					error: false,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					data: softwareProjects.reduce<Record<string, any>>((acc, project) => {
						let projectDeepDiveInfo;
						if (project.projectType === PRODUCT_DISCOVERY_PROJECT) {
							projectDeepDiveInfo = buildProjectDeepDiveInfo(
								baseUrl,
								project,
								undefined,
								undefined,
								{
									count: -1,
									error: false,
									loading: true,
									message: formatMessage(messages.assignedToMeIdeas),
								},
							);
						} else {
							projectDeepDiveInfo = buildProjectDeepDiveInfo(
								baseUrl,
								project,
								{
									count: -1,
									error: false,
									loading: true,
									message: formatMessage(
										expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
											? messages.assignedToMeIssueTermRefresh
											: messages.assignedToMe,
									),
								},
								{
									count: -1,
									error: false,
									loading: false,
									message: formatMessage(
										expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
											? messages.doneIssuesIssueTermRefresh
											: messages.doneIssues,
									),
								},
							);
						}

						return {
							// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							...acc,
							...projectDeepDiveInfo,
						};
					}, {}),
				});
				const fetchResult = await fetchMyAssignedIssuesInProjects(baseUrl, softwareProjects);
				if (fetchResult && fetchResult.data) {
					!didCancel &&
						setResults({
							loading: false,
							error: false,
							data: buildDeepDiveInfoItems(fetchResult),
						});
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (fetchError: any) {
				trackFetchErrors({
					error: fetchError,
					id: 'fetchAssignedIssueCountPerProject',

					packageName: 'deepDiveCards',
				});

				!didCancel &&
					setResults({
						loading: false,
						error: true,
						data: buildDeepDiveInfoItems(),
					});
			}
		})();
		return () => {
			didCancel = true;
		};
		// Disabling lint rule to help avoid extra api calls due to changing recentProject object references between re-renders
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseUrl, formatMessage, JSON.stringify(recentProjects)]);
	return results;
};

export default useFetchAssignedIssuesCountPerProject;
