import React, { useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { boardsDataQuery } from '@atlassian/jira-relay/src/__generated__/boardsDataQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { TabProps } from '../../../common/types/tabs.tsx';
import { BoardsTable } from './boards-table/index.tsx';
import { EmptyState } from './empty-state/index.tsx';
import { BoardsErrorState } from './error-state/index.tsx';
import { LoadingState } from './loading-state/index.tsx';

// TODO: Top bar with search field (https://jplat.atlassian.net/browse/BLU-3708) and
// TODO: "Create board" button that opens existing modal (https://jplat.atlassian.net/browse/BLU-3707)
const BoardsTabWithoutErrorBoundary = ({ maxItems }: Pick<TabProps, 'maxItems'>) => {
	const cloudId = useCloudId();

	// To paginate, we need to get ALL boards to have the correct count.
	// If the 'first' or 'last' filters are used to limit the results fetched, it also affects totalCount.
	// So here we fetch a minimal amount of data, and then have the child components fetch details when they need them.
	// The alternative would be to use a "Load more" pattern instead of discrete-page style pagination.
	const data = useLazyLoadQuery<boardsDataQuery>(
		graphql`
			query boardsDataQuery($cloudAri: String!) {
				search {
					result: search(
						experience: "jira.search"
						filters: { entities: ["jira.board"], locations: [$cloudAri] }
					) {
						totalCount
						...boardsTable_home
					}
				}
			}
		`,
		{ cloudAri: `ari:cloud:platform::site/${cloudId}` },
	);

	const totalBoards: number = useMemo(
		() => data.search?.result?.totalCount ?? 0,
		[data?.search?.result],
	);

	return (
		<>
			{totalBoards > 0 && data?.search?.result ? (
				<BoardsTable data={data?.search?.result} itemsPerPage={maxItems} />
			) : (
				<EmptyState />
			)}
		</>
	);
};

// This uses TabProps to match the other tabs, which is why it's called maxItems instead of itemsPerPage here
export const BoardsTab = ({ maxItems }: Omit<TabProps, 'itemProviderResult'>) => {
	// TODO is it a problem that LoadingState always assumes there is data here?
	return (
		<Placeholder name="boards" fallback={<LoadingState hasData />}>
			<JSErrorBoundary
				id="homePageYourWorkBoards"
				packageName="jiraHome"
				fallback="flag"
				teamName="jira-cosmos"
				render={({ error }) => <BoardsErrorState error={error} />}
			>
				<BoardsTabWithoutErrorBoundary maxItems={maxItems} />
			</JSErrorBoundary>
		</Placeholder>
	);
};
