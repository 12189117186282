import React from 'react';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import JiraHomeApp from '@atlassian/jira-home/src/ui/index.tsx';
import { SCREEN } from '@atlassian/jira-product-analytics-bridge';
import SubmitApdex from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/index.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { SpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { jiraHomePageLoad } from '../utils/performance-analytics/index.tsx';
import type { Props } from './types.tsx';

const JiraHome = ({ prefetchResult }: Props) => (
	<>
		<JiraHomeApp prefetchResult={prefetchResult} />
		<SubProductUpdater subProduct="platform" />
		{!prefetchResult.loading && (
			<>
				<SubmitApdex
					appName="jira-home"
					isExpectedToHaveSsr
					// @ts-expect-error - TS2769 - No overload matches this call.
					metric={jiraHomePageLoad}
					emitOnAnimationFrame
				/>
				<SpaStatePageReady />
			</>
		)}
	</>
);

export default AnalyticsSource('jiraHome', SCREEN)(JiraHome);
