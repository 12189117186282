import React from 'react';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const GlobalPagesDocumentTitle = () => {
	const { formatMessage } = useIntl();

	return <DocumentTitle title={formatMessage(messages.pageTitleYourWork)} />;
};
