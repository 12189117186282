import React from 'react';
import { YourWorkItemListSkeleton } from '@atlassian/jira-skeletons/src/ui/your-work/YourWorkItemListSkeleton.tsx';
import { BOARDS_TAB_KEY } from '../../../../common/constants/tab-content.tsx';
import { useFireErrorOrSuccessAnalytics } from '../../../../common/utils.tsx';

type LoadingStateProps = {
	hasData: boolean;
};

export const LoadingState = ({ hasData }: LoadingStateProps) => {
	useFireErrorOrSuccessAnalytics(hasData, null, true, BOARDS_TAB_KEY);

	return <YourWorkItemListSkeleton />;
};
