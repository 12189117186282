import React, { useCallback, useEffect, useState } from 'react';
import { withChoreographedRender } from '@atlassian/jira-choreographer-services/src/withChoreographedRender.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { usePageContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-context/main.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { JSM_HR_EXPANSION_SPOTLIGHT_MESSAGE } from './common/constants.tsx';
import { HrExpansionSpotlightView } from './ui/index.tsx';

const HrExpansionSpotlightWithDelay = () => {
	const [shouldShowSpotlight, setShouldShowSpotlight] = useState<boolean>(false);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setShouldShowSpotlight(true);
		}, 2000);
		return () => clearTimeout(timeoutId);
	}, []);
	return shouldShowSpotlight && <HrExpansionSpotlightView />;
};

const ChoreographedCustomMessageComponent = withChoreographedRender(HrExpansionSpotlightWithDelay);

const ChoreographedHrSpotlight = () => {
	const isAdmin = useIsAdmin();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ data: pageContextData }] = usePageContext();
	const { projectId, projectType } = pageContextData?.projectContext || {};

	useEffect(() => {
		if (isAdmin && expVal('jsm_hr_expansion', 'cohort', 'not-enrolled') !== 'not-enrolled') {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'statsigExperiment exposed',
				'jsm_hr_expansion',
				{
					cohort: expVal('jsm_hr_expansion', 'cohort', 'not-enrolled'),
					projectId,
					projectType,
				},
			);
		}
	}, [createAnalyticsEvent, isAdmin, projectId, projectType]);
	return isAdmin && expValEquals('jsm_hr_expansion', 'cohort', 'experiment') ? (
		<ChoreographedCustomMessageComponent messageId={JSM_HR_EXPANSION_SPOTLIGHT_MESSAGE} />
	) : null;
};

export const HrExpansionSpotlight = () => {
	const emptyFallback = useCallback(() => <></>, []);
	return (
		<JSErrorBoundary
			id="jira-servicedesk-hr-expansion-experiment-spotlight"
			packageName="jiraServicedeskHrExpansionExperiment"
			teamName="jsd-getting-started"
			fallback={emptyFallback}
		>
			<ChoreographedHrSpotlight />
		</JSErrorBoundary>
	);
};
